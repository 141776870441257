<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.persons_groups') }}</h1>

        <EmployeesGroupsTable @change-page="changePage($event)"
                              @change-amount="changeAmount($event)"
                              @sort="sortingColumn = $event"
                              @fetch="fetchEmployeesGroups"
                              :loader="loader"
                              :changed-page="pagination.page"></EmployeesGroupsTable>

        <ManageEmployeesGroup @fetch="fetchEmployeesGroups"></ManageEmployeesGroup>
    </div>
</template>

<script>
import EmployeesGroupsTable from '@/components/employeesGroups/EmployeesGroupsTable';
import ManageEmployeesGroup from '@/components/employeesGroups/manageEmployeesGroup/ManageEmployeesGroup';

export default {
    name: 'EmployeesGroupsConfigurations',
    components: { EmployeesGroupsTable, ManageEmployeesGroup },
    data: () => ({
        loader: false,
        pagination: {
            page: 1,
            amount: 20
        },
        sortingColumn: null
    }),
    watch: {
        sortingColumn: {
            handler () {
                this.fetchEmployeesGroups();
            },
            deep: true
        }
    },
    methods: {
        fetchEmployeesGroups () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                include: 'employees'
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            return this.$store.dispatch('employeesGroups/getEmployeesGroups', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchEmployeesGroups();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchEmployeesGroups();
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.EMPLOYEE_GROUP)) {
                vm.fetchEmployeesGroups();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped>

</style>
