<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs"
                            tag="div"
                            class="px-4">
            <TextField v-model="name"
                       name="name"
                       ref="name"
                       rules="required"
                       :label="$t('labels.employee_group_name')"></TextField>
            <Autocomplete :label="$t('navigation.employees')"
                          :items="employees"
                          name="employeesIds"
                          clearable
                          :loading="isEmployeesLoading"
                          :search-input.sync="searchEmployees"
                          @click:clear="getEmployees"
                          :multiple="true"
                          @load-more-items="getEmployeesLazyLoading"
                          v-model="employeesIds"></Autocomplete>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';

export default {
    name: 'ManageEmployeesGroupForm',
    mixins: [debouncedEmployees],
    components: { ValidationObserver, TextField, Autocomplete },
    computed: {
        ...mapFields('manageEmployeesGroup', [
            'groupData.name',
            'groupData.employeesIds'
        ]),
        ...mapState('manageEmployeesGroup', [
            'groupFormVisibility'
        ]),
        ...mapGetters('employees', [
            'employees'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    watch: {
        groupFormVisibility: {
            handler (val) {
                if (val) {
                    const params = {};
                    if (this.employeesIds.length > 0) {
                        params['filter[id]'] = this.employeesIds.join(',');
                    }
                    this.getEmployees(params);
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
